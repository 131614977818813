import * as React from "react";
import "./procedure-model.css";
import Layout from "../layouts/layout";
import { Link } from "gatsby";
import parse from 'html-react-parser';
import favicon from "../images/favico.ico";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image"



const ProcedurePage = (props) => {
    const { content } = props.pageContext;
    const { title } = props.pageContext;
    const { titleMeta } = props.pageContext;
    const { metaDesc } = props.pageContext;
    const { childs } = props.pageContext;
    const { uri } = props.pageContext;
    return (
        <Layout>
            <Helmet htmlAttributes={{
                lang: 'fr-FR',
            }}>
                <link rel="icon" type="image/x-icon" href={favicon} />
                <title>{titleMeta && titleMeta}</title>
                <meta name="description" content={metaDesc} />
                <meta name="robots" content="index, follow" />
                <link data-react-helmet="true" rel="canonical" href={"https://www.medica-tour.fr" + uri}></link>
                <meta name="msvalidate.01" content="BF6180D2B9843FD051D8456A095B9C38" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-TSKFDDEV9E"></script>
                    <script>
      {`
        window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-TSKFDDEV9E');
      `}
    </script>
    <script>
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MC7KJQM');`}
    </script>
            </Helmet>
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC7KJQM"
height="0" width="0" style={{display:"none",visibility:"hidden"}} ></iframe></noscript>
            <div className="container-fluid service4 pt-5 pb-5">
                <div className="container intern-page-style">
                    <h1 className="service-title">
                        {title}
                    </h1>
                </div>
            </div>

            <div className="container mt-4">
                <p className="lead">
                    Pour plus de détails concernant votre séjour médical, veuillez cliquer sur les liens ci-dessous:
                </p>
                <div className="row">
                    {
                        childs &&
                        childs.map((child) => {
                            if(child.title=="Séjour Esthétique en Tunisie : Tout Compris avec MedicaTour"){
                            return (
                                <div className="col-sm-12 col-md-4 pt-2">
                                    <div className="card h-100" >
                                        <Link to={child.uri} className="link-interv">
                                        <StaticImage src="../images/sejour-medical.webp" className="card-img-top img-fluid" alt={child.title} />

                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if(child.title=="Clinique Esthétique en Tunisie : Soins de Qualité et Expertise Médicale"){
                            return (
                                <div className="col-sm-12 col-md-4 pt-2">
                                    <div className="card h-100" >
                                        <Link to={child.uri} className="link-interv">
                                        <StaticImage src="../images/clinique.webp" className="card-img-top img-fluid" alt={child.title} />

                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if(child.title=="Hôtel de Chirurgie Esthétique en Tunisie"){
                            return (
                                <div className="col-sm-12 col-md-4 pt-2">
                                    <div className="card h-100" >
                                        <Link to={child.uri} className="link-interv">
                                        <StaticImage src="../images/hotel-de-chirugie-esthetique-.webp" className="card-img-top img-fluid" alt={child.title} />

                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        })

                    }

                </div>
            </div>

            <div className="container-fluid pb-5 pt-2 blog-single">
                <div className="container ">
                    <div className="row">

                        {content && parse(content)}


                    </div>
                </div>
            </div>


        </Layout>
    )
}

export default ProcedurePage
